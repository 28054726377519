import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import * as d3 from 'd3';
import './App.css';
import moment from 'moment';

function App() {
    const [activeTab, setActiveTab] = useState('heute');

    const renderContent = () => {
        switch (activeTab) {
            case 'heute':
                return <TabHeute />;
            case 'temperatur':
                return <TabTemperatur />;
            case 'niederschlag':
                return <TabNiederschlag />;
            default:
                return null;
        }
    };

    return (
        <div className="App-root">
            <h1>Wetter Dashboard</h1>
            <div className="tabs">
                <button onClick={() => setActiveTab('heute')}>Heute</button>
                <button onClick={() => setActiveTab('temperatur')}>Temperatur</button>
                <button onClick={() => setActiveTab('niederschlag')}>Niederschlag</button>
            </div>
            <div id="tabs-content">
                {renderContent()}
            </div>
        </div>
    );
}

const TabHeute = () => {
    const [data, setData] = useState({ x: [], y: [] });

    useEffect(() => {
      // CSV-Datei mit Semikolon als Trennzeichen laden
      d3.dsv(";", `${process.env.PUBLIC_URL}/data.csv`).then((csvData) => {
          console.log(csvData); // Überprüfe die geladenen Daten

          // Bereinige die Felder und wandle sie in Zahlen um
          csvData = csvData.map(row => {
              // Entferne führende und nachfolgende Leerzeichen und wandle die relevanten Spalten in Zahlen um
              Object.keys(row).forEach(key => {
                  row[key] = row[key].trim(); // Entferne Leerzeichen
              });
              
              // Konvertiere die relevanten Felder in Zahlen
              row['Temperatur'] = parseFloat(row['Temperatur']);
              row['Feuchte'] = parseFloat(row['Feuchte']);
              
              return row;
          });
          console.log(csvData); // Überprüfe die geladenen Daten
          // Datum/Zeit konvertieren und in ein Array von JavaScript-Dateien umwandeln
          const dates = csvData.map(row => moment(row['Datum/Zeit'], "DD.MM.YYYY HH:mm:ss").toDate());
          const temperatures = csvData.map(row => row['Temperatur']);
          const humidity = csvData.map(row => row['Feuchte']);

          // Setze die Daten in den Zustand
          setData({ x: dates, temperature: temperatures, humidity: humidity });
      }).catch(error => {
          console.error("Fehler beim Laden der CSV-Datei:", error);
      });
  }, []);

    return (
        <div>
            <h2>Wetter Heute</h2>
            <Plot
                data={[
                    { type: 'scatter', mode: 'lines+markers', x: data.x, y: data.y, marker: { color: 'blue' } }
                ]}
                layout={{ title: 'Temperaturverlauf über die Zeit', xaxis: { title: 'Datum' }, yaxis: { title: 'Temperatur (°C)' } }}
            />
        </div>
    );
};

const TabTemperatur = () => (
    <div>
        <h2>Temperatur und Feuchte</h2>
        <p>Hier könnten Daten zu Temperatur und Feuchte angezeigt werden.</p>
    </div>
);

const TabNiederschlag = () => (
    <div>
        <h2>Niederschlag und Wolken</h2>
        <p>Hier könnten Daten zu Niederschlag und Wolken angezeigt werden.</p>
    </div>
);

export default App;
